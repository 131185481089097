<template>
  <div>
    <ve-table
      id="kilometers-table"
      fixed-header
      max-height="calc(100vh - 330px)"
      :columns="isteam === 'my' ? columns : spv_columns"
      :table-data="tableData"
      :border-around="true"
      :border-x="true"
      :border-y="true"
      :scroll-width="1000"
      :sort-option="sortOption"
      :cell-style-option="cellStyleOption"
    />
    <div v-show="showEmpty" class="empty-data">Data Empty.</div>
    <div class="table-pagination mt-2 text-right">
      <ve-pagination
        :total="totaltableData"
        :page-index="pageIndex"
        :page-size="pageSize"
        :page-size-option="pageOptions"
        @on-page-number-change="pageNumberChange"
        @on-page-size-change="pageSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import moment from "moment";
import _ from "lodash";
import PictureInput from "vue-picture-input";
import download from "js-file-download";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import GenneraPictureInput from "@/components/GenneraPictureInput.vue";

export default {
  name: "Kilometers",
  props: ["isteam", "year", "month", "userId"],
  components: {
    PictureInput,
    GenneraPictureInput,
    CoolLightBox
  },
  data() {
    return {
      lightboxindex: null,
      status_type: ["Pendiente Aprobación", "Incidencia", "Aprobado"],
      user_roles: [],
      user_status: [],
      expenseTypes: [],
      vehicleTypeList: [],
      tableData: [],
      totaltableData: 0,

      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        date: {},
        username: {},
        companyName: {},
        parent_username: {},
        approvername: {},
        user_status: {},
        usertype: {},
        expenseTypeId: {},
        approvalStatus: {},
        totalKM: {},
        startKM: {},
        endKM: {},
        parentId: {},
        vehicleType: {}
      },
      filterable_col_list: [
        "date",
        "username",
        "companyName",
        "user_status",
        "usertype",
        "parent_username",
        "route",
        "approvername",
        "gpv_comment",
        "spv_comment",
        "expenseTypeId",
        "approvalStatus",
        "startKM",
        "endKM",
        "totalKM",
        "parentId",
        "vehicleType"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          let className = "";
          if (column.field !== "status") {
            if (rowIndex % 2 !== 0) {
              className = "table-body-cell-even";
            } else {
              className = "table-body-cell-odd";
            }
          } else {
            if (row.status === "Aceptado") className += " status-acception";
            else if (row.status === "Pte aceptacion")
              className += " status-pte-acception";
            else if (row.status === "No aceptado")
              className += " status-no-acception";
          }
          return className;
        }
      },
      showEmpty: false,

      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      kmNumRules: v => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true;
        return "Este campo debe estar por encima de 0";
      },
      kmTotalNumRules: v => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true;
        return "Los KM de inicio no pueden ser mayores que los KM final";
      },
      dateRules: [v => !!v || "Required", v => "La fecha ya tiene KM"],
      dateLqApprovedRules: [
        v => !!v || "Required",
        v => "No se pueden anadir kms porque la liquidacion ha sido aprobada."
      ],
      dateOnHolidayLeaveRules: [
        v => !!v || "Required",
        v => "No se puede crear KM porque está de vacaciones o de bajas."
      ],
      /********** create or edit dialog options **********/
      showEdit: false,
      selected: null,
      editedIndex: -1,
      search_routes: null,
      isRoutesSearching: false,
      filtered_routes: [],
      default_routes: [],
      select_date_menu: false,
      nowDate: new Date().toISOString().slice(0, 10),
      reactive: false,
      current_approvalStatus_class: "error",
      isSaving: false,
      selectable_mindate: new Date().toISOString().slice(0, 10),
      isDeleting: false,

      /********** image dialog options **********/
      showImageDialog: false,
      selected_image: null,

      filterable_parent_list: []
    };
  },
  watch: {
    $route(to, from) {
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        this.filterCriteria[key] = Object.assign({});
      }
      this.init();
    },
    async search_routes(val) {
      this.isRoutesSearching = true;
      let fetch_url = `expenses/get_selectable_routes?filter_name=${val}`;
      const resp = await ApiService.get(fetch_url);
      this.filtered_routes = resp.data;
      this.isRoutesSearching = false;
      if (this.filtered_routes.length === 0) {
        this.filtered_routes = this.default_routes;
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "isGPV", "user"]),
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? ""
        : " - " + this.$t("edititem");
    },
    noDataPending() {
      const {
        date,
        routeId,
        startKM,
        endKM,
        startPhoto,
        endPhoto,
        current_selected_date_data_id,
        id,
        is_already_exist,
        isChecking
      } = this.selected;
      if (
        date &&
        startKM >= 0 &&
        endKM >= 0 &&
        (startPhoto.img || startPhoto.static) &&
        (endPhoto.img || endPhoto.static) &&
        (id === current_selected_date_data_id || !is_already_exist)
      ) {
        if (!isChecking && this.isGPV && !routeId) {
          return false;
        }
        return true;
      }
      return false;
    },
    col_date() {
      return {
        key: "date",
        title: this.$t("Date"),
        field: "date",
        width: 50,
        sortBy: "",
        fixed: "left"
      };
    },
    col_comercial() {
      return {
        key: "comercial",
        title: "NOMBRE COMERCIAL",
        field: "comercial",
        width: 50,
        sortBy: ""
      };
    },
    col_visita() {
      return {
        key: "visita",
        title: "Tipo Visita",
        field: "visita",
        width: 50,
        sortBy: ""
      };
    },
    col_observacions() {
      return {
        key: "observacions",
        title: `OBSERVACIONES`,
        field: "observacions",
        width: 100,
        sortBy: ""
      };
    },
    col_editar() {
      return {
        key: "editar",
        title: "Editar",
        field: "editar",
        align: "center",
        width: 30,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-icon
                medium
                color="primary"
                class="mr-2"
                on-click={() => this.onItemEdit(row)}
              >
                mdi-pencil
              </v-icon>
            </div>
          );
        }
      };
    },
    spv_columns() {
      return [
        this.col_date,
        this.col_comercial,
        this.col_visita,
        this.col_observacions,
        this.col_editar
      ];
    },
    columns() {
      return [
        this.col_date,
        this.col_comercial,
        this.col_visita,
        this.col_observacions,
        this.col_editar
      ];
    }
  },
  methods: {
    setDateFrom() {
      let twoMonthsAgo = new Date();
      twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
      twoMonthsAgo = moment(twoMonthsAgo)
        .local()
        .format("YYYY-MM-DD");
      this.filterCriteria["date"] = {
        from: twoMonthsAgo,
        to: ""
      };
    },
    onChangeApprovalStatus(val) {
      switch (val) {
        case "Aprobado":
          this.current_approvalStatus_class = "success";
          break;
        case "Incidencia":
          this.current_approvalStatus_class = "warning";
          break;
        case "Pendiente Aprobación":
          this.current_approvalStatus_class = "primary";
          break;
        default:
          this.current_approvalStatus_class = "error";
          break;
      }
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#kilometers-table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (
        cancelFilterKey === "startKM" ||
        cancelFilterKey === "endKM" ||
        cancelFilterKey === "totalKM"
      ) {
        this.filterCriteria[cancelFilterKey]["from"] = "";
        this.filterCriteria[cancelFilterKey]["to"] = "";
      } else if (
        cancelFilterKey === "route" ||
        cancelFilterKey === "gpv_comment" ||
        cancelFilterKey === "spv_comment"
      ) {
        this.filterCriteria[cancelFilterKey] = null;
      } else {
        this.filterCriteria[cancelFilterKey] = {};
      }
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (key === "date") {
            let convert_value = {};
            if (
              value["from"] !== "" &&
              value["from"] !== null &&
              value["from"] !== undefined
            ) {
              convert_value["from"] = value["from"];
            }
            if (
              value["to"] !== "" &&
              value["to"] !== null &&
              value["to"] !== undefined
            ) {
              convert_value["to"] = value["to"];
            }
            if (Object.keys(convert_value).length > 0) {
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
          } else if (
            key === "startKM" ||
            key === "endKM" ||
            key === "totalKM"
          ) {
            if (
              (value["from"] !== "" &&
                value["from"] !== null &&
                value["from"] !== undefined) ||
              (value["to"] !== "" &&
                value["to"] !== null &&
                value["to"] !== undefined)
            ) {
              filterModel.push({
                columnField: key,
                filterValue: value
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(
        "#kilometers-table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      if (this.isteam !== undefined) {
        query_params_string += "&isteam=" + this.isteam;
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "expenses/km";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        // const resp = await ApiService.get(fetch_url);
        this.tableData = [
          {
            id: 1,
            date: "19/01/2025",
            comercial: "Laura",
            visita: "Telefonica",
            observacions: "Modificaciones de presupuesto. Pasar Ignacio."
          },
          {
            id: 2,
            date: "15/01/2025",
            comercial: "Ignacio",
            visita: "Presencial",
            observacions: "Nueva visita. Preparar presupuesto"
          },
          {
            id: 3,
            date: "09/10/2024",
            comercial: "Laura",
            visita: "Telefonica",
            observacions:
              "Aceptan presupuesto, pendiente que hablen con Ignacio."
          },
          {
            id: 4,
            date: "30/09/2024",
            comercial: "Ignacio",
            visita: "Telefonica",
            observacions: "Piden cambios en el presupuesto."
          },
          {
            id: 5,
            date: "27/09/2024",
            comercial: "Ignacio",
            visita: "Telefonica",
            observacions: "Confirmo han recibido presupuesto."
          },
          {
            id: 6,
            date: "16/09/2024",
            comercial: "Ignacio",
            visita: "Presencial",
            observacions: "Primera visita."
          }
        ];
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaltableData = this.tableData?.total || 0;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    onItemShowImageDialog(item, image) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.selected_image = this.getImageUri(image ? image : null);
      this.showImageDialog = true;
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    closeImageDialog() {
      this.showImageDialog = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    onChangeStartKM() {
      this.changeKM();
    },
    onChangeEndKM() {
      this.changeKM();
    },
    changeKM() {
      if (
        this.selected.startKM !== undefined &&
        this.selected.endKM !== undefined
      ) {
        this.selected.startKM = parseFloat(this.selected.startKM);
        this.selected.endKM = parseFloat(this.selected.endKM);
        this.selected.totalKM =
          parseFloat(this.selected.endKM) -
          parseFloat(this.selected.startKM) -
          // this.deductAmountPerdayKM;
          this.user.discount_km;
      }
    },
    createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        date: _.get(item, "date"),
        userId: _.get(item, "userId"),
        routeId: _.get(item, "routeId"),
        vehicleType: _.get(item, "vehicleType"),
        startKM: _.get(item, "startKM"),
        endKM: _.get(item, "endKM"),
        totalKM: _.get(item, "totalKM"),
        approvalStatus: _.get(item, "approvalStatus"),
        initialApprovalStatus: _.get(item, "approvalStatus"),
        gpv_comment: _.get(item, "gpv_comment"),
        spv_comment: _.get(item, "spv_comment"),
        startPhoto: {
          img: null,
          static: _.get(item, "startPhoto")
        },
        endPhoto: {
          img: null,
          static: _.get(item, "endPhoto")
        },
        approver: _.get(item, "approver"),
        route: _.get(item, "route"),
        user: _.get(item, "user"),
        other_users_data_count: 0,
        is_already_exist: false,
        current_selected_date_data_id: undefined,
        isChecking: false,
        isLiquidationApprovedCount: 0,
        isDateOnHolidayLeave: false
      };
      if (!model.vehicleType) model.vehicleType = "OWNVEHICLE";
      // this.selectable_mindate = this.getFirstdayOfOneMonthsAgo(this.nowDate);
      if (model.id === undefined) {
        model.isChecking = false;
      } else if (model.userId !== this.user.id) {
        model.isChecking = true;
      }

      if (model.route !== undefined) {
        this.filtered_routes = [model.route];
      } else if (model.id === undefined && this.isGPV) {
        this.filtered_routes = this.default_routes;
        if (this.filtered_routes.length > 0) {
          model.routeId = this.filtered_routes[0].id;
        }
      }

      if (model.id === undefined) {
        model.approvalStatus = "Pendiente Aprobación";
      }
      this.onChangeApprovalStatus(model.approvalStatus);
      model.lightboxarr = [];
      if (model.startPhoto.static) {
        model.lightboxarr.push(this.getImageUri(model.startPhoto.static));
      }
      if (model.endPhoto.static) {
        model.lightboxarr.push(this.getImageUri(model.endPhoto.static));
      }
      if (model.id === undefined) {
        if (this.$refs["pictureInputStartPhoto"] !== undefined) {
          this.$refs["pictureInputStartPhoto"].removeImage();
        }
        if (this.$refs["pictureInputEndPhoto"] !== undefined) {
          this.$refs["pictureInputEndPhoto"].removeImage();
        }
      }
      console.log(model);
      return model;
    },
    onPictureStartPhoto(img) {
      this.selected.startPhoto.img = this.$refs["pictureInputStartPhoto"].file;
    },
    onPictureEndPhoto(img) {
      this.selected.endPhoto.img = this.$refs["pictureInputEndPhoto"].file;
    },
    getImageUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getImageUri(imgStatic);
    },
    getThumbnailUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getThumbnailUri(imgStatic);
    },
    async downloadImage(selected, downloadFile) {
      try {
        const response = await ApiService.get(this.selected_image, {
          responseType: "arraybuffer"
        });
        download(response, "download.png");
      } catch (error) {
        // this.close();
      }
    },
    async downloadSelectedImage(downloadFile) {
      let selected_image = this.getImageUri(downloadFile ? downloadFile : null);
      try {
        const response = await ApiService.get(selected_image, {
          responseType: "arraybuffer"
        });
        download(response, "download.png");
      } catch (error) {
        // this.close();
      }
    },
    async onSaveClick(item) {
      const response = await this.get_initial_data(
        this.selected.date,
        this.selected.routeId
      );
      if (response.isDateOnHolidayLeave) {
        this.selected.isDateOnHolidayLeave = response.isDateOnHolidayLeave;
      }
      if (response !== null) {
        this.selected.other_users_data_count = response.other_users_data_count;
      }
      if (
        !this.selected.isChecking &&
        this.selected.totalKM >= 150 &&
        !this.selected.gpv_comment
      ) {
        logError("Has hecho más de 150KM. Por favor rellena los comentarios.");
        this.$refs.textarea_gpv_comment.focus();
      }
      if (
        !this.selected.isChecking &&
        this.selected.other_users_data_count > 0 &&
        !this.selected.gpv_comment
      ) {
        logError(
          "Esta ruta ya tiene un usuario que ha pasado kilómetros ese mismo día, por favor complete el motivo de los comentarios."
        );
        this.$refs.textarea_gpv_comment.focus();
      }
      if (
        this.selected.isChecking &&
        this.selected.approvalStatus === "Incidencia" &&
        !this.selected.spv_comment
      ) {
        logError("Los comentarios del responsable son necesarios.");
        this.$refs.textarea_spv_comment.focus();
      }
      if (this.$refs.form.validate()) {
        if (item.startKM > item.endKM) {
          logError("Los KM de inicio no pueden ser mayores que los KM final.");
          return;
        }
        let is_valid = true;

        if (is_valid) {
          try {
            this.isSaving = true;
            let body = _.omit(item, ["startPhoto", "endPhoto"]);
            let imgStart = item.startPhoto.img;
            let imgEnd = item.endPhoto.img;
            if (imgStart) {
              let { data } = await ApiService.uploadImage(imgStart);
              body.startPhotoId = data.id;
            } else if (!item.startPhoto.static) {
              logError("Se requiere una foto de inicio.");
              this.isSaving = false;
              return;
            }
            if (imgEnd) {
              let { data } = await ApiService.uploadImage(imgEnd);
              body.endPhotoId = data.id;
            } else if (!item.endPhoto.static) {
              logError("Se requiere una foto final.");
              this.isSaving = false;
              return;
            }
            if (this.noDataPending && !this.selected.isChecking) {
              body.approvalStatus = "Pendiente Aprobación";
            }
            console.log(body);
            if (body.id) {
              await ApiService.put(`expenses/km/${body.id}`, body);
            } else {
              await ApiService.post(`expenses/km`, body);
            }
            this.$store.dispatch("auth/getGlobalState");
            logInfo("Éxito !");
            this.showEdit = false;
            this.getDataFromApi();
            this.isSaving = false;
          } catch (error) {
            this.isSaving = false;
          }
        }
      }
    },
    async get_initial_data(date, routeId) {
      try {
        let fetch_url =
          "expenses/km/get_initial_data/" +
          date +
          "/" +
          routeId +
          "/" +
          this.selected.userId;
        const response = await ApiService.get(fetch_url);
        return response;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    async onChangeData() {
      let date = this.selected.date;
      let routeId = this.selected.routeId;
      const response = await this.get_initial_data(date, routeId);
      if (response !== null) {
        if (response.data !== null) {
          this.selected.is_already_exist = true;
          this.selected.current_selected_date_data_id = response.data.id;
        } else {
          this.selected.is_already_exist = false;
          this.selected.current_selected_date_data_id = undefined;
        }
        this.selected.other_users_data_count = response.other_users_data_count;
        this.selected.isLiquidationApprovedCount =
          response.isLiquidationApprovedCount;
        this.selected.isDateOnHolidayLeave = response.isDateOnHolidayLeave;
      }
    },
    async onChangeVehicleType() {
      // if (this.selected.vehicleType !== "OWNVEHICLE") {
      //   this.selected.startKM = 0;
      //   this.selected.endKM = 0;
      //   this.changeKM();
      // }
    },
    async onClickDelete(item) {
      if (window.confirm(this.$t("confirmdelete"))) {
        this.isDeleting = true;
        try {
          await ApiService.delete(`expenses/km/${item.id}`);
          this.isDeleting = false;
          this.getDataFromApi();
          this.showEdit = false;
          logInfo("Éxito !");
        } catch (error) {
          console.log(error);
          this.isDeleting = false;
        }
      }
    },
    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "expenses/km/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "kmdata.xlsx");
      this.close();
    },
    init() {
      this.setDateFrom();
      this.getDataFromApi();
    }
  },
  mounted() {
    if (Object.keys(this.$route.params).length > 0) {
      if (
        this.$route.params.year &&
        this.$route.params.month &&
        this.$route.params.userId
      ) {
        this.criteria = {
          year: this.$route.params.year,
          month: this.$route.params.month,
          userId: this.$route.params.userId
        };
        let start_end_date = this.getFirstAndLastDateOfMonth(
          this.criteria.year,
          this.criteria.month
        );
        this.filterCriteria.date = {
          from: start_end_date.firstDate,
          to: start_end_date.lastDate
        };
        this.filterCriteria.userId = this.criteria.userId;
      }
    }
    this.init();
  }
};
</script>

<style>
.status_wrapper {
  text-align: center;
}
.status_wrapper .row {
  margin-top: 0;
  color: #000;
}
#kilometers-table tbody img {
  width: 100% !important;
}
input[type="date"]:before {
  color: lightgrey;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}
input[type="date"]:focus:before {
  content: "" !important;
}
input.filter_date_picker {
  width: 100%;
  border: 1px solid #999;
  margin-top: 10px;
}
.lightbox_image_item {
  max-width: 100%;
  height: 300px;
  cursor: pointer;
  background-position: center;
  background-size: contain;
}
.title_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title_wrapper .btn_status {
  cursor: initial;
}
.picture-inner,
.picture-preview {
  z-index: 1000 !important;
}
.error--text input[type="number"] {
  color: #f00;
}
.status-acception {
  background-color: #e0faff !important;
}
.status-pte-acception {
  background-color: #f5ebc1 !important;
}
.status-no-acception {
  background-color: #f8bd9b !important;
}
</style>
